jQuery(document).ready(function () {
    var $password = $('#password');
    if ($password.length) {
        $("#show-password").click(function () {
            var fieldType = $password.attr('type');
            if ('password' === fieldType) {
                $password.attr('type', 'text');
            } else {
                $password.attr('type', 'password');
            }
        })
    }
    // ============= FILTERS ================
    var $filterForm = $(".filter-form");
    $('.js-filter').find('.datepicker').datepicker({format: 'dd.mm.yyyy', language: 'ru', todayHighlight: true});

    $filterForm.submit(function () {
        $(this).find(":input").filter(function () {
            return !this.value;
        }).attr("disabled", "disabled");
        return true; // ensure form still submits
    });
    $filterForm.find('[type=reset]').click(function (e) {
        e.preventDefault();
        $filterForm.find(':input[type=text],select').val('');
        $filterForm.find(':input[type=radio],:input[type=checkbox]').prop('checked', false);
        // $filterForm.find('[type=submit]').trigger("click")
    });
    // Un-disable form fields when page loads, in case they click back after submission
    // $filterForm.find(":input").prop("disabled", false);

    // ================ EXCEL EXPORTS ======================
    $('.excel-export').click(function () {
        let tableId = $(this).data('table');
        if (typeof tableId !== 'undefined' && tableId) {
            let opts = {};
            let name = $(this).data('filename');
            if (typeof name !== 'undefined') {
                if (!name.endsWith('.xlsx')) {
                    name += '.xlsx';
                }
                opts.name = name;
            }
            let table = document.querySelector('#' + tableId);
            TableToExcel.convert(table, opts);
        }
    });

    $('.admin-user-action').click(function () {
        var action = $(this).data('action');
        var user = $(this).data('user');
        var confirmMsg = $(this).data('confirm');
        var $form = $('.users-manage-form');
        if ($form.length && action) {
            $form.find('input[name=u]').val(user);
            $form.find('input[name=action]').val(action);
            if (typeof confirmMsg !== 'undefined' && confirmMsg.length) {
                if (confirm(confirmMsg)) {
                    $form[0].submit();
                }
            } else {
                $form[0].submit();
            }
        }
    });

    $('.vote-action').click(function () {
        var action = $(this).data('action');
        var user = $(this).data('user');
        var confirmMsg = $(this).data('confirm');
        var $form = $('.votes-manage-form');
        if ($form.length && action) {
            if (action === 'update') {
                $form.find('input[name=vote]').val($(this).closest('tr').find('input[name=vote]').val());
            }
            $form.find('input[name=u]').val(user);
            $form.find('input[name=action]').val(action);
            if (typeof confirmMsg !== 'undefined' && confirmMsg.length) {
                if (confirm(confirmMsg)) {
                    $form[0].submit();
                }
            } else {
                $form[0].submit();
            }
        }
    });

    $(".login-form").each(function () {
        $(this).validate({
            rules: {email: {email: true}},
            messages: {
                login: "Введите идентификатор пользователя",
                password: "Введите пароль"
            },
        });
    });

    $('#db-upload-form').find('[name="config"]').on('change', function () {
        var self = this;
        $(self).closest('.input-file').find('[name="file"]').val(this.files.length ? self.files[0].name : '');
    });

});